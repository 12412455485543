import { Component } from 'vue-property-decorator';

import LayoutHeader from './components/layout-header/layout-header.vue';

import FormBaseSetting from './components/form-base-setting/form-base-setting.vue';
import ProcessDesign from './components/process-design/process-design.vue';
import Ellipsis from './components/ellipsis/ellipsis.vue';
import WarnDialog from './components/warn-dialog/warn-dialog.vue';
import { NodeTreeModule } from '@/store/modules/node-tree';
import { Message } from 'element-ui';
import { debounce, messageError, translation } from '@/utils';
import { ValidResult } from '@/resource/model/system-setting/approval-setting';
import { workflowDesignService } from '@/api';
import { mixins } from 'vue-class-component';
import { ConvertTreeDataMixin } from './convert-tree-data-mixin';
import { TagsViewModule } from '@/store/modules/tags-view';

@Component({
  components: { LayoutHeader, Ellipsis, WarnDialog, ProcessDesign, FormBaseSetting }
})
export default class ApprovalSetting extends mixins(ConvertTreeDataMixin) {
  public id: number | null = null;
  public type: 'add' | 'edit' = 'add';
  public isNew: boolean = true;
  public validStep: number = 0;
  public timer: any = null;
  public activeSelect: string = 'baseSetting';
  public validVisible: boolean = false;
  public validResult: ValidResult = {
    title: '',
    action: '',
    desc: '',
    errs: [],
    finished: false,
    success: false
  };
  public validOptions: Array<{ title: string; description: string; icon: string; status: string }> = [
    { title: '基础信息', description: '', icon: '', status: '' },
    { title: '审批流程', description: '', icon: '', status: '' }
  ];
  public validComponents: any = ['baseSetting', 'processDesign'];

  private status: 0 | 1 = 0;

  private get setup(): any {
    return NodeTreeModule.design;
  }
  private get errTitle(): any {
    if (this.validResult.finished && !this.validResult.success) {
      return this.validResult.title + ` (${this.validResult!.errs!.length}项错误)`;
    }
    return this.validResult.title;
  }
  private get validIcon(): any {
    if (!this.validResult.finished) {
      return 'el-icon-loading';
    } else if (this.validResult.success) {
      return 'success';
    } else {
      return 'warning';
    }
  }
  @debounce()
  public loadFormInfo(): void {
    try {
      workflowDesignService
        .getById(this.id!)
        .then(res => {
          const form = res;
          form.process = JSON.parse(res.flowInfoJson);
          NodeTreeModule.setLoadForm(form);
        })
        .catch(error => {
          messageError(error);
        });
    } catch (error) {
      messageError(error);
    }
  }
  public loadInitFrom(): void {
    NodeTreeModule.setLoadForm({
      id: undefined,
      name: '',
      code: '',
      document: '',
      status: 0,
      tag: '',
      remark: '',
      process: {
        id: 'root',
        parentId: '',
        type: 'ROOT',
        name: '发起人',
        desc: '任何人',
        props: {
          assignedUser: [],
          assignedType: 'ASSIGN_USER',
          mode: 'NEXT'
        },
        children: {}
      }
    });
  }
  public validateDesign(): void {
    this.validVisible = true;
    this.validStep = 0;
    this.showValiding();
    this.stopTimer();
    if (this.validStep === 0) {
      this.timer = setInterval(async () => {
        this.validResult.errs = await (this.$refs[this.validComponents[this.validStep]] as any).validate();
        if (Array.isArray(this.validResult.errs) && this.validResult.errs.length === 0) {
          this.validStep++;
          if (this.validStep >= this.validOptions.length) {
            this.stopTimer();
            this.showValidFinish(true);
          }
        } else {
          this.stopTimer();
          this.validOptions[this.validStep].status = 'error';
          this.showValidFinish(false, this.getDefaultValidErr());
        }
      }, 800);
    } else {
      this.timer = setInterval(() => {
        this.validResult.errs = (this.$refs[this.validComponents[this.validStep]] as any).validate();
        if (Array.isArray(this.validResult.errs) && this.validResult.errs.length === 0) {
          this.validStep++;
          if (this.validStep >= this.validOptions.length) {
            this.stopTimer();
            this.showValidFinish(true);
          }
        } else {
          this.stopTimer();
          this.validOptions[this.validStep].status = 'error';
          this.showValidFinish(false, this.getDefaultValidErr());
        }
      }, 800);
    }

    this.showValidFinish(true);
  }
  public getDefaultValidErr(): string {
    switch (this.validStep) {
      case 0:
        return '请检查基础设置项';
      case 1:
        return '请检查审批流程，查看对应标注节点错误信息';
      default:
        return '未知错误';
    }
  }
  public showValidFinish(success: boolean, err?: any): void {
    this.validResult.success = success;
    this.validResult.finished = true;
    this.validResult.title = success ? '校验完成' : '校验失败 ';
    this.validResult.desc = success ? '设置项校验成功，是否提交？' : err;
    this.validResult.action = success ? '提 交' : '去修改';
  }
  public showValiding(): void {
    this.validResult = {
      errs: [],
      finished: false,
      success: false,
      title: '检查中...',
      action: '处理',
      desc: '正在检查设置项'
    };
    this.validStep = 0;
    this.validOptions.forEach(op => {
      op.status = '';
      op.icon = '';
      op.description = '';
    });
  }
  public doAfter(): void {
    if (this.validResult.success) {
      if (this.status === 1) {
        this.doPublish();
      } else {
        this.save(0);
      }
    } else {
      this.activeSelect = this.validComponents[this.validStep];
      this.validVisible = false;
    }
  }
  public stopTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  public publishProcess(status: 0 | 1): void {
    this.status = status;
    this.validateDesign();
  }
  public async save(status: 0 | 1): Promise<void> {
    try {
      const result: any = {
        id: undefined,
        code: '',
        name: '',
        document: '',
        tag: '',
        remark: '',
        status: undefined,
        flowInfoJson: '',
        processNode: {}
      };
      result.id = this.setup.id;
      result.code = this.setup.code;
      result.name = this.setup.name;
      result.document = this.setup.document;
      result.tag = this.setup.tag;
      result.remark = this.setup.remark;
      result.status = status;
      this.convertTreeData(this.setup.process, result.processNode);
      result.flowInfoJson = JSON.stringify(this.setup.process);
      if (!this.setup.id) {
        await workflowDesignService.post(result);
      } else {
        await workflowDesignService.put(result);
      }
      Message.success(translation('operationRes.operationSuccess'));
      this.close();
      this.validVisible = false;
    } catch (error) {
      messageError(error as any);
    }
  }
  public close(): void {
    this.$router.go(-1);
    TagsViewModule.DelView(this.$route);
  }
  public doPublish(): void {
    this.$confirm('发布后流程立即生效，是否继续?', '提示', {
      confirmButtonText: '发布',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      try {
        this.save(1);
      } catch (error) {
        messageError(error as any);
      }
    });
  }

  public activated(): void {
    if (Number(this.$route.query.id)) {
      this.type = 'edit';
      this.id = Number(this.$route.query.id);
      this.loadFormInfo();
    } else {
      this.type = 'add';
      this.loadInitFrom();
    }
  }

  public created(): void {
    this.loadInitFrom();
    if (Number(this.$route.query.id)) {
      this.type = 'edit';
      this.id = Number(this.$route.query.id);
      this.loadFormInfo();
    } else {
      this.type = 'add';
    }
  }
}
