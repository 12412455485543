import { NodeTreeModule } from '@/store/modules/node-tree';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'OrgItems',
  components: {}
})
export default class OrgItems extends Vue {
  // @Prop({ required: false, default: [], type: Array })
  // public value: Array<any> = [];
  private get assignedUser(): Array<{ id: number; name: string }> {
    return NodeTreeModule.selectedNode.props?.assignedUser as Array<{ id: number; name: string }>;
  }
  public removeOrgItem(index: number): void {
    this.assignedUser.splice(index, 1);
  }
}
