import { NodeTreeModule } from '@/store/modules/node-tree';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Node from '../node/node.vue';

@Component({
  name: 'ApprovalNode',
  components: { Node }
})
export default class ApprovalNode extends Vue {
  // 是否为根节点
  @Prop({ required: false, default: {}, type: Object })
  public config: any;

  public showError: boolean = false;
  public errorInfo: string = '';

  private get content(): string {
    const config = this.config.props;
    let str = '';
    switch (config.assignedType) {
      case 'ASSIGN_USER':
        if (config.assignedUser.length > 0) {
          const texts: Array<string> = [];
          config.assignedUser.forEach((org: any) => texts.push(org.name));
          str = String(texts.splice(0, 3)).replaceAll(',', '、');
          if (config.assignedUser.length >= 4) {
            str = str + '...';
          }
          return str;
        } else {
          return '请指定审批人';
        }
      case 'DIRECT_MANAGER':
        return '直属上级';
      case 'SELF':
        return '发起人自己';
      case 'SELF_SELECT':
        return config.selfSelect.multiple ? '发起人自选多人' : '发起人自选一人';
      case 'LEADER_TOP':
        return '多级主管依次审批';
      case 'LEADER':
        return config.leader.level > 1 ? '发起人的第 ' + config.leader.level + ' 级主管' : '发起人的直接主管';
      case 'ROLE':
        if (config.role.length > 0) {
          return String(config.role).replaceAll(',', '、');
        } else {
          return '指定角色（未设置）';
        }
      default:
        return '未知设置项😥';
    }
  }

  public getFormItemById(id: any): any {
    return (NodeTreeModule.design as any).formItems.find((item: any) => item.id === id);
  }
  // 校验数据配置的合法性
  public validate(err: any): boolean {
    this.showError = false;
    if (this.config.props.assignedType === 'ASSIGN_USER' && this.config.props.assignedUser.length === 0) {
      this.showError = true;
      this.errorInfo = '请选择需要审批的人员';
    }
    if (this.showError) {
      err.push(`审批节点 ${this.config.name} 未设置审批人`);
    }
    return !this.showError;
  }
  public validate_ASSIGN_USER(err: any): Boolean {
    if (this.config.props.assignedUser.length > 0) {
      return true;
    } else {
      this.errorInfo = '请指定审批人员';
      err.push(`${this.config.name} 未指定审批人员`);
      return false;
    }
  }
  public validate_SELF_SELECT(err: any): Boolean {
    console.log(err);
    return true;
  }
  public validate_LEADER_TOP(err: any): Boolean {
    console.log(err);
    return true;
  }
  public validate_LEADER(err: any): Boolean {
    console.log(err);
    return true;
  }
  public validate_ROLE(err: any): Boolean {
    if (this.config.props.role.length <= 0) {
      this.errorInfo = '请指定负责审批的系统角色';
      err.push(`${this.config.name} 未指定审批角色`);
      return false;
    }
    return true;
  }
  public validate_SELF(err: any): Boolean {
    console.log(err);
    return true;
  }
  public validate_FORM_USER(err: any): Boolean {
    if (this.config.props.formUser === '') {
      this.errorInfo = '请指定表单中的人员组件';
      err.push(`${this.config.name} 审批人为表单中人员，但未指定`);
      return false;
    }
    return true;
  }
  public validate_REFUSE(err: any): Boolean {
    console.log(err);
    return true;
  }
}
