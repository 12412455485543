import projectConfigService from '@/api/system-setting/project-config';
import { projectConfig } from '@/resource/model/system-setting/project-config';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ProjectConfig extends Vue {
  public form: projectConfig = {
    id: 0,
    approvalFlag: 1,
    timeThreshold: 10
  };

  public loading: boolean = false;

  /**
   * 保存配置
   */
  public async save(): Promise<void> {
    this.loading = true;
    try {
      await projectConfigService.put({ ...this.form } as any);
      Message.success(translation('operationRes.editSuccess'));
    } catch (error) {
      messageError(error);
    } finally {
      this.loading = false;
    }
  }
  /**
   * 获取配置信息
   */
  public info(): void {
    projectConfigService
      .getProjectConfigs()
      .then(res => {
        this.form = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public created(): void {
    this.info();
  }

  public activated(): void {
    this.info();
  }
}
