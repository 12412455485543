import { NodeTreeModule } from '@/store/modules/node-tree';
import { isNotEmpty } from '@/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'LayoutHeader',
  components: {}
})
export default class LayoutHeader extends Vue {
  @Prop({ type: String, required: false, default: 'baseSetup' })
  public value!: string;
  public viewCode: boolean = false;

  private get setup(): any {
    return NodeTreeModule.design;
  }

  public publish(): void {
    this.$emit('save', 1);
  }
  public save(): void {
    this.$emit('save', 0);
  }
  public preview(): void {
    this.$emit('preview');
    this.viewCode = true;
  }
  public valid(): boolean {
    if (!isNotEmpty(this.setup.group)) {
      this.$message.warning('请选择分组');
      this.$router.push('/layout/baseSetup');
      // TODO
      return false;
    }
    return true;
  }
  /**
   * 关闭
   */
  public exit(): void {
    this.$confirm('内容未保存，是否直接退出 ?', '提示', {
      confirmButtonText: '退出',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$emit('close');
    });
  }
  public to(path: string): void {
    this.$emit('input', path);
  }
  public handleSelect(key: any, keyPath: any): void {
    console.log(key, keyPath);
  }
  // public listener(): void {
  //   window.onunload = this.closeBefore();
  //   window.onbeforeunload = this.closeBefore();
  //   // window.on('beforeunload',this.closeBefore())
  // }
  // public closeBefore(): any {
  //   // alert("您将要离开本页")
  //   return false;
  // }
  public check(): void {
    if (NodeTreeModule.isEdit === null) {
      // this.$router.push("/workPanel");
    }
  }
}
