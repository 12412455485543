import { TreeNode } from '@/resource/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Node from '../node/node.vue';
@Component({
  name: 'RootNode',
  components: { Node }
})
export default class RootNode extends Vue {
  @Prop({
    required: true,
    default: () => {
      return {};
    },
    type: Object
  })
  public config!: TreeNode;
  public showError: boolean = false;
  public errorInfo: string = '';

  private get content(): String {
    if (this.config!.props!.assignedUser.length > 0) {
      const texts: any[] = [];
      this.config!.props!.assignedUser.forEach((org: { name: any }) => texts.push(org.name));
      return String(texts).replaceAll(',', '、');
    } else {
      return '所有人';
    }
  }
  public validate(err: any): boolean {
    this.showError = false;
    if (!this.config.children?.id) {
      this.showError = true;
      this.errorInfo = '审批流程未添加节点';
    }
    if (this.showError) {
      err.push(`审批流程未添加节点`);
    }
    return !this.showError;
  }
}
