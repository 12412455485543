import { ResourceUrlEnum } from '@/resource/enum';
import { projectConfig } from '@/resource/model/system-setting/project-config';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';

class ProjectConfigService {
  /**
   * 获取配置信息
   * @returns
   */
  public getProjectConfigs(): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(`${ResourceUrlEnum.systemProject}/config`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 修改配置信息
   * @param params
   * @returns
   */
  public put(params: projectConfig): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<projectConfig>(`${ResourceUrlEnum.systemProject}/config`, params)
        .then((res: ApiResponse<projectConfig>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const projectConfigService = new ProjectConfigService();
export default projectConfigService;
