import { NodeTreeModule } from '@/store/modules/node-tree';
import { Component, Vue } from 'vue-property-decorator';
import Approval from '../approval-node-config/approval-node-config.vue';
// import Condition from './ConditionNodeConfig.vue'
// import Delay from './DelayNodeConfig.vue'
import Cc from '../cc-node-config/cc-node-config.vue';
// import Trigger from './TriggerNodeConfig.vue'
// import FormAuthorityConfig from './FormAuthorityConfig.vue'
// import Root from './RootNodeConfig.vue';

@Component({
  components: {
    Approval,
    Cc
  }
})
export default class NodeConfig extends Vue {
  public active: string = 'properties';
  private get selectNode(): any {
    return NodeTreeModule.selectedNode;
  }
  private get name(): any {
    switch (this.selectNode.type) {
      case 'ROOT':
        return '设置发起人';
      case 'APPROVAL':
        return '设置审批人';
      case 'CC':
        return '设置抄送人';
      default:
        return null;
    }
  }
}
