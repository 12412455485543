import { workflowDesignService } from '@/api/';
import { DataDictionaryService } from '@/api/data-dictionary';
import { Design } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { NodeTreeModule } from '@/store/modules/node-tree';

import { debounce, isNullOrUndefinedForBaseType, messageError, translation } from '@/utils';
import { Form } from 'element-ui';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'FormBaseSetting',
  components: {}
})
export default class FormBaseSetting extends Vue {
  @Prop({ required: true, default: 'add', type: String })
  public type!: 'add' | 'edit';
  public formRules = {
    code: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          workflowDesignService
            .checkWorkflowCode(value, this.form?.id)
            .then((isRepeat: boolean) => {
              (this as FormBaseSetting).allowCode = isRepeat;
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          workflowDesignService
            .checkWorkflowName(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.regionNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    tag: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.input') + translation('workflowDesign.tag')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    document: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('workflowDesign.document')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };
  public documentOptions: NormalSelectOptions = [];
  private allowName: boolean = false;
  private allowCode: boolean = false;
  private get form(): Design {
    return NodeTreeModule.design;
  }
  private get isEdit(): boolean {
    return this.type === 'edit';
  }

  public changeDocument(value: string): void {
    this.form.document = this.documentOptions.filter(d => d.value === value)[0].label;
  }

  public async validate(): Promise<Array<string>> {
    const err: Array<string> = [];
    const checkWorkflowName = await workflowDesignService.checkWorkflowName(this.form.name, this.form?.id);
    const checkWorkflowCode = await workflowDesignService.checkWorkflowCode(this.form.code, this.form?.id);
    (this.$refs.baseSetting as Form).validate();
    if (isNullOrUndefinedForBaseType(this.form.name)) {
      err.push('基本信息【名称】未填写');
    }
    if (checkWorkflowName) {
      err.push('基本信息【名称】已存在');
    }

    if (isNullOrUndefinedForBaseType(this.form.code)) {
      err.push('基本信息【编码】未填写');
    }
    if (checkWorkflowCode) {
      err.push('基本信息【编码】已存在');
    }

    if (isNullOrUndefinedForBaseType(this.form.document)) {
      err.push('基本信息【单据】未填写');
    }
    if (isNullOrUndefinedForBaseType(this.form.tag)) {
      err.push('基本信息【流程标识】未填写');
    }
    return err;
  }
  public created(): void {
    this.getWorkflowOrderType();
  }
  public activated(): void {
    this.getWorkflowOrderType();
  }

  @debounce()
  private async getWorkflowOrderType(): Promise<void> {
    try {
      this.documentOptions = (await DataDictionaryService.getWorkflowOrderType()).map(x => {
        return {
          label: x.value,
          value: x.code
        };
      });
    } catch (error) {
      messageError(error);
    }
  }
}
