import { Component, Vue } from 'vue-property-decorator';
export interface WorkflowForm {
  id?: number;
  /**
   * 编码
   */
  code: string;
  /**
   * 名称
   */
  name: string;
  /**
   * 单据
   */
  document: string;
  /**
   *
   */
  remark: string;
  /**
   *
   */
  status: 1 | 2;
  /**
   * 标识
   */
  tag: string;
  /**
   * 前端流程图信息
   */
  flowInfo: JSON;
  /**
   *
   */
  processNode: BackendNode;
}
export interface BackendNode {
  id: string;
  name: string;
  /**
   * 节点类型
   */
  nodeType: string;
  branch: string;
}

@Component
export class ConvertTreeDataMixin extends Vue {
  public handleFlowData(): any {}
  /**
   * 数据处理
   * @param process
   * @param processNode
   */
  public convertTreeData(process: any, processNode: any): void {
    processNode.id = process.id;
    processNode.name = process.id + '-' + process.name;
    processNode.multiMode = this.getMultiMode(process.props.mode);
    processNode.nodeType = this.getNodeType(process.type);
    processNode.paramType = this.getParamType(process.props.assignedType);
    processNode.paramName = this.getParamName(process);
    processNode.paramValue = JSON.stringify(process.props.assignedUser);
    processNode.selectType = process.props.assignedType === 'DIRECT_MANAGER' ? 1 : 2;
    processNode.children = process.children?.id ? {} : null;
    if (process.children?.id) {
      this.convertTreeData(process.children, processNode.children);
    }
  }

  private getNodeType(type: string): string {
    let str = '';
    switch (type) {
      case 'ROOT':
        str = 'single';
        break;
      case 'APPROVAL':
        str = 'single';
        break;
      case 'CC':
        str = 'cc';
        break;
      case 'branch':
        str = 'branch';
        break;
      default:
        break;
    }
    return str;
  }

  private getParamType(assignedType: string): number {
    console.log('assignedType', assignedType);
    let num = 0;
    switch (assignedType) {
      case 'DIRECT_MANAGER':
        num = 1;
        break;
      case 'ASSIGN_USER':
        num = 2;
        break;
    }
    return num;
  }
  private getParamName(process: any): string {
    return process.id;
  }
  private getMultiMode(mode: string): string {
    let str = '';
    switch (mode) {
      case 'NEXT':
        str = '单人';
        break;
      case 'AND':
        str = '会签';
        break;
      case 'OR':
        str = '或签';
        break;
    }
    return str;
  }
}
