import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { WorkflowDesignList, WorkflowDesignQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { messageError, translation, dateFormat, debounce, messageErrors } from '@/utils';
import { Message, MessageBox } from 'element-ui';
import { WorkflowStatusEnum } from '@/resource/enum';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { workflowDesignService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging, SortOptions } from '@/api/base';
import { MessageBoxData } from 'element-ui/types/message-box';
import { PublicApproveStatusEnum } from '@/resource/enum/approve-status';
import { NormalSelectOptions } from '@/resource/model/common';
import { DataDictionaryService } from '@/api/data-dictionary';

@Component({
  name: 'StoreLocation',
  components: {}
})
export default class WorkflowDesign extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<WorkflowDesignList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<WorkflowDesignList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'database.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'database.name',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'document',
      label: 'workflowDesign.document',
      minWidth: '100px',
      showOverflowTooltip: true
    },

    {
      prop: 'tag',
      label: 'workflowDesign.tag',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'workflowDesign.status',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'database.description',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'database.createUserName',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'database.createTime',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as WorkflowDesignList).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<WorkflowDesignQuery>> = [
    {
      type: 'Input',
      field: 'code',
      label: 'database.code',
      option: {
        placeholder: 'database.code'
      }
    },
    {
      type: 'Input',
      field: 'name',
      label: 'database.name',
      option: {
        placeholder: 'database.name'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'workflowDesign.status',
      option: {
        placeholder: 'workflowDesign.status',
        clearable: false
      },
      optionData: this.getWorkflowStatusOptions()
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'system:model:save',
      handleClick: (): void => {
        this.add();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'system:model:delete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    },

    {
      type: 'danger',
      slot: 'start',
      label: 'workflowDesign.abandonedWorkflow',
      operationType: 'abandonedWorkflow',
      permissionCode: 'system:model:repeal',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.abandonedWorkflow();
      }
    }
    // {
    //   type: 'primary',
    //   slot: 'start',
    //   label: 'workflowDesign.resumeWorkflow',
    //   operationType: 'batchAudit',
    //   permissionCode: 'system:model:restore',
    //   disabled: true,
    //   handleClick: (): void => {
    //     this.resumeWorkflow();
    //   }
    // }
  ];

  public id: number = 0;

  public editRow: WorkflowDesignList | null = null;

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<WorkflowDesignList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'system:model:edit',
        handleClick: this.edit
      }
    ]
  };

  public selectedRows: Array<WorkflowDesignList> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private queryForm: Partial<WorkflowDesignQuery> = {
    name: '',
    code: '',
    status: undefined
  };

  private sortOptions: SortOptions<WorkflowDesignList> = this.tableOption.defaultSort!;

  private documentOptions: NormalSelectOptions = [];
  public created(): void {
    this.getWorkflowOrderType();
    this.initColumns(this.defaultColumnOptions, 'workflowDesign');
    this.loadData();
  }

  public activated(): void {
    this.getWorkflowOrderType();
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<WorkflowDesignList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public handleSelectionChange(selectedData: Array<WorkflowDesignList>): void {
    this.selectedRows = selectedData;
  }

  public getWorkflowStatusOptions(): NormalSelectOptions {
    return [
      {
        label: translation('workflowStatus.unpublished'),
        value: WorkflowStatusEnum.unpublished
      },
      {
        label: translation('workflowStatus.published'),
        value: WorkflowStatusEnum.published
      },
      {
        label: translation('workflowStatus.abandoned'),
        value: WorkflowStatusEnum.abandoned
      }
    ];
  }

  public getStatusClass(status: WorkflowStatusEnum): string {
    let str = '';
    switch (status) {
      case WorkflowStatusEnum.unpublished:
        str = 'start-dot';
        break;
      case WorkflowStatusEnum.published:
        str = 'success-dot';
        break;
      case WorkflowStatusEnum.abandoned:
        str = 'disabled-dot';
        break;
      default:
        break;
    }
    return str;
  }

  public getStatusI18Key(status: PublicApproveStatusEnum): string {
    return 'workflowStatus.' + WorkflowStatusEnum[status];
  }

  @debounce()
  private async getWorkflowOrderType(): Promise<void> {
    try {
      this.documentOptions = (await DataDictionaryService.getWorkflowOrderType()).map(x => {
        return {
          label: x.value,
          value: x.code
        };
      });
    } catch (error) {
      messageError(error);
    }
  }
  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    workflowDesignService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<WorkflowDesignList>): void {
    this.operationOptions.forEach(x => {
      if (!['add'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  private clearSelection(): void {
    (this.$refs.workflowDesignTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private add(): void {
    console.log('打开弹窗');
    // 新增数据
    this.$router.push({
      path: '/approval-setting',
      query: {
        id: null
      }
    });
  }
  private edit(rowData: WorkflowDesignList): void {
    // 修改数据
    this.editRow = rowData as WorkflowDesignList;
    this.$router.push({
      path: '/approval-setting',
      query: {
        id: `${rowData.id}`
      }
    });
  }

  private dialogClosed(): void {
    this.id = 0;
  }
  private deleteConfirm(): Promise<MessageBoxData> {
    return MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    });
  }
  /**
   * 批量删除
   */
  private async batchDelete(): Promise<void> {
    const idList: Array<number> = this.selectedRows
      .filter(item => Number(item.status) === WorkflowStatusEnum.unpublished)
      .map(x => x.id!);

    if (idList.length === 0) {
      Message.warning(translation('workflowDesign.selectDeleteTip'));
      return;
    }
    this.deleteConfirm()
      .then(async () => {
        try {
          await workflowDesignService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 废弃
   */
  private async abandonedWorkflow(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows
        .filter(item => Number(item.status) === WorkflowStatusEnum.published)
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('workflowDesign.selectRepealTip'));
        return;
      }
      await workflowDesignService.batchRepeal(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageErrors(error);
    }
  }

  /**
   * 恢复
   */
  private async resumeWorkflow(): Promise<void> {
    try {
      if (this.selectedRows.length > 1) {
        Message.warning(translation('common.selectMaxDataTip'));
        return;
      }
      const idList: Array<number> = this.selectedRows
        .filter(item => Number(item.status) === WorkflowStatusEnum.abandoned)
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('workflowDesign.selectRestoreTip'));
        return;
      }
      await workflowDesignService.batchRestore(idList);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageErrors(error);
    }
  }
}
