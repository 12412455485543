import { Component, Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash-es';

// 导入所有节点组件
import Approval from './components/nodes/approval-node/approval-node.vue';
import Empty from './components/nodes/empty-node/empty-node.vue';
import Root from './components/nodes/root-node/root-node.vue';
import Node from './components/nodes/node/node.vue';
import { NodeTreeModule } from '@/store/modules/node-tree';
import { NodeProps, TreeNode } from '@/resource/model';
import DefaultProps from './DefaultNodeProps';
import Cc from './components/nodes/cc-node/cc-node.vue';
// import Concurrent from '@/views/common/process/nodes/ConcurrentNode.vue'
// import Condition from '@/views/common/process/nodes/ConditionNode.vue'
// import Trigger from '@/views/common/process/nodes/TriggerNode.vue'
// import Delay from '@/views/common/process/nodes/DelayNode.vue'

@Component({
  name: 'Process',
  components: { Node, Root, Approval, Empty, Cc }
})
export default class Process extends Vue {
  public valid: boolean = true;

  private get nodeMap(): Map<string, Object> {
    return NodeTreeModule.nodeMap;
  }

  private get dom(): TreeNode {
    return NodeTreeModule.design.process as TreeNode;
  }

  public render(h: Function): any {
    // console.log('开始渲染流程树');
    this.nodeMap.clear();
    const processTrees = this.getDomTree(h, this.dom);
    // 插入末端节点
    processTrees.push(
      h('div', { style: { 'text-align': 'center' } }, [
        h('div', { class: { 'process-end': true }, domProps: { innerHTML: '流程结束' } })
      ])
    );
    return h('div', { class: { _root: true }, ref: '_root' }, processTrees);
  }

  public getDomTree(h: Function, node: TreeNode): Array<any> {
    this.toMapping(node);
    if (this.isPrimaryNode(node)) {
      // 普通业务节点
      const childDoms = this.getDomTree(h, node.children!);
      this.decodeAppendDom(h, node, childDoms);
      return [h('div', { class: { 'primary-node': true } }, childDoms)];
    } else if (this.isBranchNode(node)) {
      let index = 0;
      // 遍历分支节点，包含并行及条件节点
      const branchItems = node.branchs!.map((branchNode: TreeNode) => {
        // 处理每个分支内子节点
        this.toMapping(branchNode);
        const childDoms = this.getDomTree(h, branchNode.children!);
        this.decodeAppendDom(h, branchNode!, childDoms, { level: index + 1, size: node.branchs!.length });
        // 插入4条横线，遮挡掉条件节点左右半边线条
        this.insertCoverLine(h, index, childDoms, node.branchs!);
        // 遍历子分支尾部分支
        index++;
        return h('div', { class: { 'branch-node-item': true } }, childDoms);
      });
      // 插入添加分支/条件的按钮
      branchItems.unshift(
        h('div', { class: { 'add-branch-btn': true } }, [
          h(
            'el-button',
            {
              class: { 'add-branch-btn-el': true },
              props: { size: 'small', round: true },
              on: { click: () => this.addBranchNode(node) },
              domProps: { innerHTML: `添加${this.isConditionNode(node) ? '条件' : '分支'}` }
            },
            []
          )
        ])
      );
      const bchDom = [h('div', { class: { 'branch-node': true } }, branchItems)];
      // 继续遍历分支后的节点
      const afterChildDoms = this.getDomTree(h, node.children!);
      return [h('div', {}, [bchDom, afterChildDoms])];
    } else if (this.isEmptyNode(node)) {
      // 空节点，存在于分支尾部
      const childDoms = this.getDomTree(h, node.children!);
      this.decodeAppendDom(h, node, childDoms);
      return [h('div', { class: { 'empty-node': true } }, childDoms)];
    } else {
      // 遍历到了末端，无子节点
      return [];
    }
  }
  // 解码渲染的时候插入dom到同级
  // eslint-disable-next-line max-params
  public decodeAppendDom(h: Function, node: TreeNode, dom: any[], props: any = {}): void {
    console.log(node);
    props.config = node;
    dom.unshift(
      h(
        node.type!.toLowerCase(),
        {
          props: props,
          ref: node.id,
          key: node.id,
          // 定义事件，插入节点，删除节点，选中节点，复制/移动
          on: {
            insertNode: (type: String) => this.insertNode(type as string, node),
            delNode: () => this.delNode(node),
            selected: () => this.selectNode(node),
            copy: () => this.copyBranch(node),
            leftMove: () => this.branchMove(node, -1),
            rightMove: () => this.branchMove(node, 1)
          }
        },
        []
      )
    );
  }

  // id映射到map，用来向上遍历
  public toMapping(node: any): void {
    if (node?.id) {
      // console.log("node=> " + node.id + " name:" + node.name + " type:" + node.type)
      this.nodeMap.set(node.id, node);
    }
  }
  // eslint-disable-next-line max-params
  public insertCoverLine(h: Function, index: number, doms: any[], branchs: string | any[]): void {
    if (index === 0) {
      // 最左侧分支
      doms.unshift(h('div', { class: { 'line-top-left': true } }, []));
      doms.unshift(h('div', { class: { 'line-bot-left': true } }, []));
    } else if (index === branchs.length - 1) {
      // 最右侧分支
      doms.unshift(h('div', { class: { 'line-top-right': true } }, []));
      doms.unshift(h('div', { class: { 'line-bot-right': true } }, []));
    }
  }
  public copyBranch(node: TreeNode): void {
    const parentNode: TreeNode = this.nodeMap.get(node.parentId!) as TreeNode;
    const branchNode = cloneDeep(node);
    branchNode.name = branchNode.name + '-copy';
    this.forEachNode(parentNode, branchNode, (parent: { id: any }, node: { id: string; parentId: any }) => {
      const id = this.getRandomId();
      console.log(node, '新id =>' + id, '老nodeId:' + node.id);
      node.id = id;
      node.parentId = parent.id;
    });
    if (parentNode.branchs?.length) {
      parentNode.branchs.splice(parentNode.branchs.indexOf(node), 0, branchNode);
    }
    this.$forceUpdate();
  }
  public branchMove(node: TreeNode, offset: number): void {
    const parentNode: TreeNode = this.nodeMap.get(node.parentId!) as TreeNode;
    if (parentNode.branchs?.length) {
      const index: number = parentNode.branchs.indexOf(node) as number;
      const branch = parentNode.branchs[index + offset];
      parentNode.branchs[index + offset] = parentNode.branchs[index];
      parentNode.branchs[index] = branch;
    }
    this.$forceUpdate();
  }
  // 判断是否为主要业务节点
  public isPrimaryNode(node: TreeNode): boolean {
    return (
      node &&
      (node.type === 'ROOT' ||
        node.type === 'APPROVAL' ||
        node.type === 'CC' ||
        node.type === 'DELAY' ||
        node.type === 'TRIGGER')
    );
  }
  public isBranchNode(node: TreeNode): boolean {
    return node && (node.type === 'CONDITIONS' || node.type === 'CONCURRENTS');
  }
  public isEmptyNode(node: TreeNode): boolean {
    return node && node.type === 'EMPTY';
  }
  // 是分支节点
  public isConditionNode(node: TreeNode): boolean {
    return node.type === 'CONDITIONS';
  }
  // 是分支节点
  public isBranchSubNode(node: TreeNode): boolean {
    return node && (node.type === 'CONDITION' || node.type === 'CONCURRENT');
  }
  public isConcurrentNode(node: TreeNode): boolean {
    return node.type === 'CONCURRENTS';
  }
  public getRandomId(): string {
    return `node_${new Date()
      .getTime()
      .toString()
      .substring(5)}${Math.round(Math.random() * 9000 + 1000)}`;
  }
  // 选中一个节点
  public selectNode(node: TreeNode): void {
    NodeTreeModule.setSelectedNode(node);
    this.$emit('selectedNode', node);
  }
  // 处理节点插入逻辑
  public insertNode(type: string, parentNode: TreeNode): void {
    // (this.$refs['_root'] as any).click();
    // 缓存一下后面的节点
    const afterNode: TreeNode = parentNode.children as TreeNode;
    // 插入新节点
    parentNode.children = {
      id: this.getRandomId(),
      parentId: parentNode.id,
      props: {} as NodeProps,
      type: type
    };
    switch (type) {
      case 'APPROVAL':
        this.insertApprovalNode(parentNode);
        break;
      case 'CC':
        this.insertCcNode(parentNode);
        break;
      // case 'DELAY':
      //   this.insertDelayNode(parentNode);
      //   break;
      // case 'TRIGGER':
      //   this.insertTriggerNode(parentNode);
      //   break;
      // case 'CONDITIONS':
      //   this.insertConditionsNode(parentNode);
      //   break;
      // case 'CONCURRENTS':
      //   this.insertConcurrentsNode(parentNode);
      //   break;
      default:
        break;
    }
    // 拼接后续节点
    if (this.isBranchNode({ type: type })) {
      if (afterNode?.id && parentNode.children.children) {
        afterNode.parentId = parentNode.children.children.id;
      }
      this.$set(parentNode.children.children as TreeNode, 'children', afterNode);
    } else {
      if (afterNode?.id) {
        afterNode.parentId = parentNode.children.id;
      }
      this.$set(parentNode.children, 'children', afterNode);
    }
    this.$forceUpdate();
  }
  public insertApprovalNode(parentNode: TreeNode): void {
    this.$set(parentNode.children as TreeNode, 'name', '审批人');
    this.$set(parentNode.children as TreeNode, 'props', cloneDeep(DefaultProps.APPROVAL_PROPS));
  }
  public insertCcNode(parentNode: TreeNode): void {
    this.$set(parentNode.children as TreeNode, 'name', '抄送人');
    this.$set(parentNode.children as TreeNode, 'props', cloneDeep(DefaultProps.CC_PROPS));
  }
  // public insertDelayNode(parentNode) {
  //   this.$set(parentNode.children, 'name', '延时处理');
  //   this.$set(parentNode.children, 'props', this.$deepCopy(DefaultProps.DELAY_PROPS));
  // }
  // public insertTriggerNode(parentNode) {
  //   this.$set(parentNode.children, 'name', '触发器');
  //   this.$set(parentNode.children, 'props', this.$deepCopy(DefaultProps.TRIGGER_PROPS));
  // }
  // public insertConditionsNode(parentNode) {
  //   this.$set(parentNode.children, 'name', '条件分支');
  //   this.$set(parentNode.children, 'children', {
  //     id: this.getRandomId(),
  //     parentId: parentNode.children.id,
  //     type: 'EMPTY'
  //   });
  //   this.$set(parentNode.children, 'branchs', [
  //     {
  //       id: this.getRandomId(),
  //       parentId: parentNode.children.id,
  //       type: 'CONDITION',
  //       props: this.$deepCopy(DefaultProps.CONDITION_PROPS),
  //       name: '条件1',
  //       children: {}
  //     },
  //     {
  //       id: this.getRandomId(),
  //       parentId: parentNode.children.id,
  //       type: 'CONDITION',
  //       props: this.$deepCopy(DefaultProps.CONDITION_PROPS),
  //       name: '条件2',
  //       children: {}
  //     }
  //   ]);
  // }
  // public insertConcurrentsNode(parentNode) {
  //   this.$set(parentNode.children, 'name', '并行分支');
  //   this.$set(parentNode.children, 'children', {
  //     id: this.getRandomId(),
  //     parentId: parentNode.children.id,
  //     type: 'EMPTY'
  //   });
  //   this.$set(parentNode.children, 'branchs', [
  //     {
  //       id: this.getRandomId(),
  //       name: '分支1',
  //       parentId: parentNode.children.id,
  //       type: 'CONCURRENT',
  //       props: {},
  //       children: {}
  //     },
  //     {
  //       id: this.getRandomId(),
  //       name: '分支2',
  //       parentId: parentNode.children.id,
  //       type: 'CONCURRENT',
  //       props: {},
  //       children: {}
  //     }
  //   ]);
  // }
  public getBranchEndNode(conditionNode: TreeNode): TreeNode {
    if (!(conditionNode.children as TreeNode) || !(conditionNode.children as TreeNode).id) {
      return conditionNode;
    }
    return this.getBranchEndNode(conditionNode.children as TreeNode);
  }
  public addBranchNode(node: TreeNode): void {
    if (node.branchs!.length < 8) {
      node.branchs!.push({
        id: this.getRandomId(),
        parentId: node.id,
        name: (this.isConditionNode(node) ? '条件' : '分支') + (node.branchs!.length + 1),
        props: this.isConditionNode(node)
          ? ((cloneDeep(DefaultProps.CONDITION_PROPS) as unknown) as NodeProps)
          : ({} as NodeProps),
        type: this.isConditionNode(node) ? 'CONDITION' : 'CONCURRENT',
        children: {}
      });
    } else {
      this.$message.warning('最多只能添加 8 项😥');
    }
  }
  // 删除当前节点
  public delNode(node: TreeNode): void {
    console.log('删除节点', node);
    // 获取该节点的父节点
    const parentNode: TreeNode = this.nodeMap.get(node.parentId!) as TreeNode;
    if (parentNode) {
      // 判断该节点的父节点是不是分支节点
      if (this.isBranchNode(parentNode) && parentNode.branchs) {
        // 移除该分支
        parentNode.branchs.splice(parentNode.branchs.indexOf(node), 1);
        // 处理只剩1个分支的情况
        if (parentNode.branchs.length < 2) {
          // 获取条件组的父节点
          const ppNode: TreeNode = this.nodeMap.get(parentNode.parentId!) as TreeNode;
          // 判断唯一分支是否存在业务节点
          if (parentNode.branchs[0].children?.id) {
            // 将剩下的唯一分支头部合并到主干
            ppNode.children = parentNode.branchs[0].children;
            ppNode.children.parentId = ppNode.id;
            // 搜索唯一分支末端最后一个节点
            const endNode: TreeNode = this.getBranchEndNode(parentNode.branchs[0]) as TreeNode;
            // 后续节点进行拼接, 这里要取EMPTY后的节点
            endNode.children = (parentNode.children as TreeNode).children;
            if (endNode.children?.id) {
              endNode.children.parentId = endNode.id;
            }
          } else {
            // 直接合并分支后面的节点，这里要取EMPTY后的节点
            ppNode.children = (parentNode.children as TreeNode).children;
            if (ppNode.children?.id) {
              ppNode.children.parentId = ppNode.id;
            }
          }
        }
      } else {
        // 不是的话就直接删除
        if (node.children?.id) {
          node.children.parentId = parentNode.id;
        }
        parentNode.children = node.children;
      }
      this.$forceUpdate();
    } else {
      this.$message.warning('出现错误，找不到上级节点');
    }
  }
  public validateProcess(): never[] {
    // console.log('节点编辑---');
    this.valid = true;
    const err: never[] = [];
    this.validate(err, this.dom);
    return err;
  }
  public validateNode(err: any, node: any): void {
    if ((this.$refs[node.id] as any).validate) {
      this.valid = (this.$refs[node.id] as any).validate(err);
    }
  }
  // 更新指定节点的dom
  public nodeDomUpdate(node: TreeNode): void {
    (this.$refs[node.id!] as Node).$forceUpdate();
  }
  // 给定一个起始节点，遍历内部所有节点
  public forEachNode(parent: TreeNode, node: TreeNode, callback: Function): void {
    if (this.isBranchNode(node)) {
      callback(parent, node);
      this.forEachNode(node, node.children!, callback);
      // eslint-disable-next-line array-callback-return
      node.branchs!.map((branchNode: TreeNode) => {
        callback(node, branchNode);
        this.forEachNode(branchNode, branchNode.children!, callback);
      });
    } else if (this.isPrimaryNode(node) || this.isEmptyNode(node) || this.isBranchSubNode(node)) {
      callback(parent, node);
      this.forEachNode(node, node.children!, callback);
    }
  }
  // 校验所有节点设置
  public validate(err: never[], node: TreeNode): any {
    if (this.isPrimaryNode(node)) {
      this.validateNode(err, node);
      this.validate(err, node.children!);
    } else if (this.isBranchNode(node)) {
      // 校验每个分支
      // eslint-disable-next-line array-callback-return
      node.branchs!.map((branchNode: TreeNode) => {
        // 校验条件节点
        this.validateNode(err, branchNode);
        // 校验条件节点后面的节点
        this.validate(err, branchNode.children!);
      });
      this.validate(err, node.children!);
    } else if (this.isEmptyNode(node)) {
      this.validate(err, node.children!);
    }
  }
}
