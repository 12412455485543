import { render, staticRenderFns } from "./approval-setting.vue?vue&type=template&id=5df943a6&scoped=true&"
import script from "./approval-setting.ts?vue&type=script&lang=ts&"
export * from "./approval-setting.ts?vue&type=script&lang=ts&"
import style0 from "./approval-setting.scoped.scss?vue&type=style&index=0&id=5df943a6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df943a6",
  null
  
)

export default component.exports