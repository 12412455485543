import { TreeNode } from '@/resource/model';
import { NodeTreeModule } from '@/store/modules/node-tree';
import { Component, Vue } from 'vue-property-decorator';

import Process from '../process/process.vue';
import NodeConfig from '../process/components/config/node-config/node-config.vue';

@Component({
  name: 'ProcessDesign',
  components: { Process, NodeConfig }
})
export default class ProcessDesign extends Vue {
  public scale: number = 100;
  public selected: Object = {};
  public showInput: boolean = false;
  public showConfig: boolean = false;

  private get selectedNode(): TreeNode {
    return NodeTreeModule.selectedNode as TreeNode;
  }

  /**
   * 校验
   */
  public validate(): any {
    return (this.$refs['process'] as Process).validateProcess();
  }

  public nodeSelected(node: any): void {
    console.log('配置节点', node);
    this.showConfig = true;
  }
}
