import { Component, Prop, Vue } from 'vue-property-decorator';
import ApprovalSetting from '../../approval-setting.vue';

@Component({
  name: 'WarnDialog',
  components: {}
})
export default class WarnDialog extends Vue {
  @Prop({ required: false, default: '', type: String })
  public title: String | undefined;

  @Prop({ required: false, default: '50%', type: String })
  public width: String | undefined;

  @Prop({ required: true, default: false, type: Boolean })
  public value!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  public clickClose!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  public closeFree!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  public showFooter!: String;

  @Prop({ required: false, default: '取消', type: String })
  public cancelText!: String;

  @Prop({ required: false, default: '', type: String })
  public okText!: String;

  @Prop({ required: false, default: true, type: Boolean })
  public border!: boolean;

  private get _value(): boolean {
    return this.value;
  }
  private set _value(val: boolean) {
    this.$emit('input', val);
  }
  public close(): void {
    this.$emit('cancel');
  }
  public closed(): void {
    (this.$parent.$parent as ApprovalSetting).validVisible = false;
  }
}
