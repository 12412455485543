import { Component, Prop, Vue } from 'vue-property-decorator';
import Node from '../node/node.vue';

@Component({
  name: 'CcNode',
  components: { Node }
})
export default class CcNode extends Vue {
  @Prop({ required: false, default: {}, type: Object })
  public config: any;
  public showError: boolean = false;
  public errorInfo: string = '';
  private get content(): string {
    let str = '';
    switch (this.config.props.assignedType) {
      case 'DIRECT_MANAGER':
        str = '直属上级';
        break;
      case 'ASSIGN_USER':
        if (this.config.props.assignedUser.length > 0) {
          const texts: Array<string> = [];
          this.config.props.assignedUser.forEach((org: any) => texts.push(org.name));
          str = String(texts.splice(0, 3)).replaceAll(',', '、');
          if (this.config.props.assignedUser.length >= 4) {
            str = str + '...';
          }
        } else {
          str = '请指定抄送人';
        }
        break;
      default:
        break;
    }
    return str;
  }
  public validate(err: any): any {
    this.showError = false;
    if (this.config.props.assignedType === 'ASSIGN_USER' && this.config.props.assignedUser.length === 0) {
      this.showError = true;
      this.errorInfo = '请选择需要抄送的人员';
    }
    if (this.showError) {
      err.push(`抄送节点 ${this.config.name} 未设置抄送人`);
    }
    return !this.showError;
  }
}
