import { ResourceUrlEnum } from '@/resource/enum';
import { DataDictionary } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { axiosService, ApiError } from './axios';

export class DataDictionaryService {
  /**
   * 获取耗损环节
   * @returns
   */
  public static getConsumeStage(): Promise<Array<DataDictionary>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<DataDictionary>>(`${ResourceUrlEnum.dataDictionary}/attritionLinkList`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取耗损环节下的耗损类型
   * @param stageCode 耗损环节code
   * @returns
   */
  public static getConsumeType(stageCode: string): Promise<Array<DataDictionary>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<DataDictionary>>(`${ResourceUrlEnum.dataDictionary}/attritionTypeList`, {
          params: { code: stageCode }
        })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取反馈类型
   * @returns
   */
  public static getFeedbackType(): Promise<Array<DataDictionary>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<DataDictionary>>(`${ResourceUrlEnum.dataDictionary}/getFeedbackType`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取审批单据类型类型
   * @returns
   */
  public static getWorkflowOrderType(): Promise<Array<DataDictionary>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<DataDictionary>>(`${ResourceUrlEnum.dataDictionary}/getInstanceOrder`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
