import { NodeTreeModule } from '@/store/modules/node-tree';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OrgItems from '../../org-items/org-items.vue';
import SelectPersonnel from '@/views/dialogs/select-personnel/select-personnel.vue';

@Component({
  name: 'ApprovalNodeConfig',
  components: { OrgItems, SelectPersonnel }
})
export default class ApprovalNodeConfig extends Vue {
  @Prop({ required: false, default: {}, type: Object })
  public config: any;

  public showSelectPersonnel: boolean = false;
  public modeDisabled: boolean = false;
  public orgPickerSelected: Array<any> = [];

  public orgPickerType: string = 'user';
  public approvalTypes: Array<{ name: string; type: string }> = [
    { name: '直属上级', type: 'DIRECT_MANAGER' },
    { name: '指定人员', type: 'ASSIGN_USER' }
    // { name: '发起人自选', type: 'SELF_SELECT' },
    // { name: '连续多级主管', type: 'LEADER_TOP' },
    // { name: '主管', type: 'LEADER' },
    // { name: '角色', type: 'ROLE' },
    // { name: '发起人自己', type: 'SELF' },
    // { name: '表单内联系人', type: 'FORM_USER' }
  ];
  private get nodeProps(): any {
    return NodeTreeModule.selectedNode.props;
  }
  private get select(): any {
    return this.config.assignedUser || [];
  }
  private get pickerTitle(): any {
    switch (this.orgPickerType) {
      case 'user':
        return '请选择人员';
      case 'role':
        return '请选择系统角色';
      default:
        return null;
    }
  }
  private get nodeOptions(): any {
    const values: Array<any> = [];
    const excType = ['ROOT', 'EMPTY', 'CONDITION', 'CONDITIONS', 'CONCURRENT', 'CONCURRENTS'];
    NodeTreeModule.nodeMap.forEach((v: { type: string; id: any; name: any }) => {
      if (excType.indexOf(v.type) === -1) {
        values.push({ id: v.id, name: v.name });
      }
    });
    return values;
  }
  private get showMode(): any {
    switch (this.nodeProps.assignedType) {
      case 'ASSIGN_USER':
        return this.nodeProps.assignedUser.length > 0;
      case 'SELF_SELECT':
        return this.nodeProps.selfSelect.multiple;
      case 'LEADER_TOP':
        return this.nodeProps.formUser !== '';
      case 'FORM_USER':
        return true;
      case 'ROLE':
        return true;
      default:
        return false;
    }
  }
  private get peopleNum(): number {
    return this.nodeProps.mode === 'NEXT' ? 1 : 10;
  }

  public selectUser(): void {
    this.showSelectPersonnel = true;
  }
  public handleUserConfirm(userArr: Array<{ id: number; realName: string; userCode: string }>): void {
    const userList = this.nodeProps.assignedUser.concat(
      userArr.map(u => {
        return { id: u.id, name: u.realName, isEdit: false, approveStatus: 0 };
      })
    );
    const uniqueArray = Array.from(new Map(userList.map((obj: any) => [JSON.stringify(obj), obj])).values()).splice(
      0,
      this.peopleNum
    );
    this.nodeProps.assignedUser = uniqueArray;
  }
  public removeOrgItem(index: any): void {
    this.select.splice(index, 1);
  }
  @Watch('nodeProps.assignedType', { immediate: true })
  private setModeDisabled(): void {
    if (this.nodeProps.assignedType === 'DIRECT_MANAGER') {
      this.modeDisabled = true;
      this.nodeProps.mode = 'NEXT';
    } else {
      this.modeDisabled = false;
    }
  }
  @Watch('nodeProps.mode', { immediate: true })
  private setAssignedUser(): void {
    this.handleUserConfirm([]);
  }
}
