import { Component, Prop, Vue } from 'vue-property-decorator';
import InsertButton from './inster-button/inster-button.vue';

@Component({
  name: 'Node',
  components: { InsertButton }
})
export default class Node extends Vue {
  // 是否为根节点
  @Prop({ required: false, default: false, type: Boolean })
  public isRoot!: boolean;
  // 是否显示节点体
  @Prop({ required: false, default: true, type: Boolean })
  public show!: boolean;
  // 节点内容区域文案
  @Prop({ required: false, default: '', type: String })
  public content!: String;
  // 节点标题
  @Prop({ required: false, default: '标题', type: String })
  public title!: String;
  //
  @Prop({ required: false, default: '请设置', type: String })
  public placeholder!: String;
  // 节点体左侧图标
  @Prop({ required: false, default: '', type: String })
  public leftIcon!: boolean;
  // 头部图标
  @Prop({ required: false, default: '', type: String })
  public headerIcon!: boolean;
  // 头背景颜色
  @Prop({ required: false, default: '#576a95', type: String })
  public headerBgc!: boolean;
  // 是否显示错误状态
  @Prop({ required: false, default: false, type: Boolean })
  public showError!: boolean;
  // 错误信息
  @Prop({ required: false, default: '无信息', type: String })
  public errorInfo!: boolean;
}
