import { TreeNode } from '@/resource/model';
import { NodeTreeModule } from '@/store/modules/node-tree';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'InsertButton',
  components: {}
})
export default class InsterButton extends Vue {
  private get selectedNode(): TreeNode {
    return NodeTreeModule.selectedNode;
  }
  public addApprovalNode(): void {
    this.$emit('insertNode', 'APPROVAL');
  }
  public addCcNode(): void {
    this.$emit('insertNode', 'CC');
  }
  public addDelayNode(): void {
    this.$emit('insertNode', 'DELAY');
  }
  public addConditionsNode(): void {
    this.$emit('insertNode', 'CONDITIONS');
  }
  public addConcurrentsNode(): void {
    this.$emit('insertNode', 'CONCURRENTS');
  }
  public addTriggerNode(): void {
    this.$emit('insertNode', 'TRIGGER');
  }
}
