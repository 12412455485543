import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { Design, TreeNode } from '@/resource/model';

export interface INodeTreeState {
  nodeMap: Map<string, Object>;
  isEdit: boolean | null;
  selectedNode: TreeNode;
  selectFormItem: null;
  design: Object;
}

@Module({ dynamic: true, store, name: 'nodeTree' })
class NodeTreeState extends VuexModule implements INodeTreeState {
  public nodeMap = new Map();
  public isEdit: boolean | null = null;
  public selectedNode: TreeNode = { name: '', type: '' } as TreeNode;
  public selectFormItem = null;
  public design = {} as Design;

  @Mutation
  public setSelectedNode(value: TreeNode): void {
    this.selectedNode = value;
  }
  @Mutation
  public setLoadForm(value: Design): void {
    this.design = value;
  }
  @Mutation
  public setIsEdit(value: boolean | null): void {
    this.isEdit = value;
  }
}

export const NodeTreeModule = getModule(NodeTreeState);
