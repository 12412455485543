import { NodeTreeModule } from '@/store/modules/node-tree';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrgItems from '../../org-items/org-items.vue';
import SelectPersonnel from '@/views/dialogs/select-personnel/select-personnel.vue';

@Component({
  name: 'CcNodeConfig',
  components: { SelectPersonnel, OrgItems }
})
export default class CcNodeConfig extends Vue {
  @Prop({ required: false, default: {}, type: Object })
  public config: any;

  public showSelectPersonnel: boolean = false;
  public modeDisabled: boolean = false;
  public orgPickerSelected: Array<any> = [];

  public orgPickerType: string = 'user';
  public approvalTypes: Array<{ name: string; type: string }> = [
    { name: '直属上级', type: 'DIRECT_MANAGER' },
    { name: '指定人员', type: 'ASSIGN_USER' }
  ];
  private get nodeProps(): any {
    return NodeTreeModule.selectedNode.props;
  }
  public selectUser(): void {
    this.showSelectPersonnel = true;
  }
  public handleUserConfirm(user: Array<{ id: number; realName: string; userCode: string }>): void {
    const userList = this.nodeProps.assignedUser.concat(
      user.map(u => {
        return { id: u.id, name: u.realName, isEdit: false, approveStatus: 5 };
      })
    );
    const uniqueArray = Array.from(new Map(userList.map((obj: any) => [JSON.stringify(obj), obj])).values()).splice(
      0,
      10
    );
    this.nodeProps.assignedUser = uniqueArray;
  }
}
